.InitialsIcon {
  text-transform: uppercase;
  width: 32px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--onschedWhite);
  font-weight: 600;
  margin: 0 15px;
  opacity: .6;
  cursor: pointer;
  overflow: hidden;
}

.InitialsIcon img {
  width: 100%;
  height: auto;
}