@media (min-width: 768px) {
  .WeekDay .long {
    display: block !important;
  }

  .WeekDay .short {
    display: none !important;
  }
}

.WeekDay .short {
  text-transform: capitalize;
}

.WeekDay .long {
  display: none;
}

td {
  padding: 0px 5px;
}

.WeekDay td:first-of-type {
  padding: 0 15px;
}

.WeekDay td:nth-of-type(3),
.WeekDay td:nth-of-type(4),
.WeekDay td:nth-of-type(5) {
  text-align: center;
}

.WeekDay .short,
.WeekDay .long {
  color: var(--onschedBlue);
  font-weight: 600;
}

.WeekDay .time {
  color: var(--onschedDark);
}