@media (min-width: 992px) {
  .UserSettings {
    max-width: 255px;
  }

  .UserSettings.compressed {
    max-width: 100px;
  }

  .UserSettings .SettingsCard {
    text-align: left;
    max-width: 220px;
  }

  .UserSettings.compressed .SettingsCard {
    text-align: center;
    width: 65px;
  }
  

  .UserSettings.compressed .SettingsCard .InitialsIcon {
    margin: 0 auto;
  }
  
  .UserSettings .switchCompanies {
    left: 62px !important;
  }
}

.scrollable .UserSettings {
  height: 125px;
  width: 100%;
  position: fixed;
  border-right: 5px solid var(--onschedBg);
  bottom: 0;
  background: var(--onschedWhite);
  box-shadow: -4px 0px 8px -1px var(--onschedLightGrey);
}

.UserSettings .SettingsCard {
  position: fixed;
  cursor: pointer;
  bottom: 15px;
  margin: 0px 15px;
  text-align: center;
  border-radius: 10px;
  padding: 13px 10px;
  font-size: .8rem;
  background-color: var(--onschedShadow);
  color: var(--onschedDark);
  font-weight: 600;
  width: calc(100% - 30px);
}

.UserSettings .SettingsCard .InitialsIcon {
  margin: 0 15px 0 0;
  display: inline-flex;
}

.UserSettings .SideNavItem {
  color: var(--onschedGrey);
  font-weight: 500;
  position: fixed;
  bottom: 75px;
  left: 0;
}

.UserSettings .SideNavItem:hover {
  color: var(--onschedBlue);
  text-decoration: none !important;
  cursor: pointer !important;
}

.UserSettings .switchCompanies  i {
  margin-right: 20px;
}