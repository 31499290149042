.SideNavItem {
  margin: 0px 15px;
  text-align: left;
  border-radius: 10px;
  padding: 13px 20px;
}

.SideNavItem.active {
  background-color: var(--onschedBlue);
}

.SideNavItem:hover a {
  color: var(--onschedBlue);
  text-decoration: none !important;
  cursor: pointer !important;
}

.SideNavItem.active a, .SideNavItem.active button {
  color: var(--onschedWhite);
}

@media (max-width: 991.98px) {
  .SideNavItem {
    text-align: center !important;
  }
  
  .SideNavItem a, .SideNavItem button {
    padding-left: 5vw;
  }
}

.SideNavItem a, .SideNavItem button {
  color: var(--onschedGrey);
  font-weight: 500;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  width: 100%;
  font-size: 1rem;
  align-items: center;
  display: flex;
  max-height: 24px;
}

.SideNavItem a:hover, .SideNavItem button:hover {
  text-decoration: none;
}

.compressed .SideNavItem i {
  margin-right: 0px;
}

.SideNavItem i {
  margin-right: 15px;
  width: 30px;
  text-align: center;
  font-size: 1.4rem;
}

.dropdown-divider {
  margin: 15px auto;
}