.ExitIntentModal .modal-dialog {
  margin-top: 30vh;
}

.ExitIntentModal .modal-content {
  border-color: var(--onschedGrey);
  border-radius: 8px;
  box-shadow: 0px 0px 24px -8px var(--onschedDark);
  font-weight: 500;
  cursor: default;
}

.ExitIntentModal .modal-content .name {
  font-weight: 600;
}

.ExitIntentModal .modal-footer {
  border-top: 0px;
}

.ExitIntentModal .modal-body {
  padding-bottom: 0px;
}