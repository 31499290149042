.DataListAction {
  display: flex;
  margin-right: 10px;
  align-items: baseline;
}

.DataListAction .delete {
  color: var(--onschedDanger)
}

.DataListAction .edit {
  color: var(--onschedBlue)
}

.DataListAction i {
  margin: 0 7px;
}

.LocationCard {
  padding: 0 15px;
  font-weight: 500;
  margin: 15px 0;
  border-radius: 10px;
  background-color: var(--onschedWhite);
}

.LocationCard td {
  padding: 5px 0;
  cursor: pointer;
  border-top: 1px solid var(--onschedWhite) ;
  border-bottom: 1px solid var(--onschedWhite) ;
}

.LocationCard:hover td {
  border-color: var(--onschedLightBlue) !important;
  background-color: var(--onschedLightBlue) !important;
}

.LocationCard i:not(:first-of-type) {
  color: var(--onschedBlue);
}

.LocationCard td:first-child,
.LocationCard td:nth-child(2) { 
  border-left: 1px solid var(--onschedWhite);
  width: 65px;
  font-weight: 600;
  color: var(--onschedGrey);
  text-align: center;
}

.LocationCard td:first-child i,
.LocationCard td:first-child:hover span {
  display: none;
}

.LocationCard td:first-child:hover i {
  display: unset;
}

.LocationCard td:first-child:active i {
  font-weight: 900 !important;
}

.LocationCard.selected td:first-child { 
  border-left: 1px solid var(--onschedBlue) !important; 
}

.LocationCard.selected td:last-child { 
  border-right: 1px solid var(--onschedBlue) !important; 
}

.LocationCard.selected td {
  border-top: 1px solid var(--onschedBlue) !important;
  border-bottom: 1px solid var(--onschedBlue) !important;
}

.LocationCard td:last-child { 
  border-top-right-radius: 6px; 
  border-right: 1px solid var(--onschedWhite);
  width: 15px; 
}

.LocationCard td:first-child { 
  border-bottom-left-radius: 6px; 
  border-top-left-radius: 6px; 
}

.LocationCard td:last-child { 
  border-bottom-right-radius: 6px; 
}
