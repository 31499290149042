@media (min-width: 992px) {
  .UserModalSettings {
    margin-top: 150px;
  }
}

.UserModalSettings {
  margin-bottom: 15px;
}

.UserModalSettings a {
  text-decoration: none;
}
