.Dashboard .Main {
  display: flex;
  background-color: var(--onschedWhite);
  min-height: calc(100vh - var(--topNavHeight));
}

.Dashboard .container {
  padding-top: 25px;
}

.Dashboard .ContentContainer {
  width: 100%;
  padding: 15px 15px 75px;
  background-color: var(--onschedBg);
  text-align: left;
}

@media (min-width: 768px) {
  .Dashboard .ContentContainer {
    padding: 25px 25px 50px;
  }
}