.SearchOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchOptions .fa-filter {
  border: 1px solid;
  border-radius: 50%;
  padding: 8px 5px 2px 5px;
  cursor: pointer;
  margin-right: 15px;
}

.SearchOptions .fa-filter.active {
  color: var(--onschedBlue);
  border-color: var(--onschedBlue);
}

.SearchOptions .search {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  color: var(--onschedDark);
  outline: none;
  cursor: pointer;
}

.SearchOptions .search i {
  font-size: 1.3em;
  margin-top: 6px;
}

.SearchOptions .FormFields {
  display: flex;
  align-items: center;
  width: 100%;
}

.SearchOptions .input-checkbox {
  width: 100px !important;
  margin-bottom: 0;
}
