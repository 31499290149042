.ConfirmModal .modal-dialog {
    margin-top: 30vh;
  }
  
  .ConfirmModal .modal-content {
    border-color: var(--onschedGrey);
    border-radius: 8px;
    box-shadow: 0px 0px 24px -8px var(--onschedDark);
    font-weight: 500;
    cursor: default;
  }
  
  .ConfirmModal .modal-content .name {
    font-weight: 600;
  }
  
  .ConfirmModal .modal-footer {
    border-top: 0px;
  }
  
  .ConfirmModal .modal-body {
    padding-bottom: 0px;
  }
