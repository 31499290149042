.ResourceModalCards {
  display: flex;
  justify-content: left;
  align-items: center;
}

.ResourceModalCards h5 {
  font-size: 1rem;
}

.ResourceModalCards p {
  margin-bottom: 0;
  opacity: .4;
  font-size: .8rem;
}

.ResourceModalCards label {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--onschedShadow);
  padding: .5rem;
  margin: 0 15px 20px 0;
  max-width: 120px;
  text-align: left;
  border-radius: 8px;
}

.ResourceModalCards label.selected {
  border-color: var(--onschedBlue);
}

.ResourceModalCards label.selected i {
  color: var(--onschedBlue);
}

.ResourceModalCards i {
  color: var(--onschedGrey);
  font-size: 1.1rem;
  margin: .5rem .4rem .5rem 0;
}

.ResourceModalCards input[type=radio] {
  opacity: 0;
  top: 12px;
  left: 25px;
  height: 115px;
  width: 120px;
  position: absolute;
  margin-right: 15px;
}

.ResourceModalCards input[type=radio]:last-of-type {
  left: 160px;
}