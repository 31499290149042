.LocationList .cells {
  display: none;
}

@media (min-width: 992px) {
  .LocationList .cells {
    display: table-cell !important;
  }
}

.LocationList th:first-child {
  text-align: center;
}

.LocationList th:nth-child(2) {
  padding-left: 65px;
}
