.WeekDayItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--onschedLightGrey);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  font-weight: 600;
  cursor: pointer;
}

.WeekDayItem.active {
  background-color: var(--onschedBlue);
  color: var(--onschedWhite)
}