:root {
  --codeAttribute : #6bc4ff;
  --codeOperator  : #c0c0c0;
  --codeKeyword   : #248bf6;
  --codeComment   : rgb(113, 248, 113);
  --codeString    : rgb(223, 156, 102);
  --codeSelector  : rgb(223, 201, 102);
}


.email-title {
  padding: 5px 5px;
  background-color: var(--onschedDark);
  outline: none;
  margin: 0 !important;
}

.email-title input {
  padding: 5px 15px !important;
  font-size: .9rem;
  font-weight: 500;
  color: var(--onschedDark);
}

.email-title label {
  color: rgba(255, 255, 255, 0.705) !important;
  font-weight: 700 !important;
  min-width: 86px;
}

.CodeEditor {
  width: 100%;
  height: 100%;
}

@media (min-width: 1415px) {
  .CodeEditor {
    margin-right: 15px;
    max-width: calc(50% - 15px);
  }
  .code-edit-container {
    max-width: 100%;
  }
}

/** ---------------------------- */
/** --- Code editor ------------ */
/** ---------------------------- */
.code-edit-container {
  position: relative;
  height: 100%;
  min-height: 1500px;
  background-color: var(--onschedDark);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 2em;
}

.code-input,
.code-output {
  position: absolute;
  top: 0;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: none;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 0.8rem !important;
  background: transparent;
  white-space: pre-wrap;
  line-height: 1.5em;
  overflow-wrap: normal !important;
  font-size: 1rem;
}

.code-input {
  opacity: 1;
  outline: none;
  margin: 0;
  font-weight: 50;
  color: hsla(0, 0%, 100%, 0);
  caret-color: var(--codeOperator);
  resize: none;
}

.code-output {
  pointer-events: none;
  z-index: 3;
  font-weight: 400;
  margin: 0;
  color: rgb(230, 230, 230);
  overflow-y: scroll;
}

.code-input,
.code-output code,
.code-output code .token {
  width: 100%;
  word-break: break-word;
}

.code-output .operator,
.code-output .punctuation {
  color: var(--codeOperator);
}

.code-output .keyword, 
.code-output .tag
{
  color: var(--codeKeyword);
}

.code-output .selector {
  color: var(--codeSelector);
}

.code-output .comment {
  color: var(--codeComment);
}

.code-output .string,
.code-output .attr-value {
  color: var(--codeString);
}

.code-output .attr-name,
.code-output .property {
  color: var(--codeAttribute);
}

code {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 1rem;
  display: block;
  color: hsl(0, 0%, 40%);
  font-size: 0.8rem;
  font-family: "PT Mono", monospace;
}

/* overrides */
.code-edit-container :not(pre) > code[class*="language-"],
.code-edit-container pre[class*="language-"] {
  background: transparent;
  margin: 0;
}