.EditorTabs {
  width: 100%;
  background-color: var(--onschedDark);
  border-radius: 8px 8px 0px 0px;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  color: rgba(255, 255, 255, 0.705);
  margin-bottom: 0;
  height: 60px;
  overflow: visible;
}

@media (min-width: 500px) {
  .EditorTabs {
    height: 33px !important;
  }
}

.EditorTabs li:not(.ParametersList) {
  padding: 2px 15px;
  cursor: pointer;
  border-radius: 8px;
  font-size: .8rem;
  font-weight: 700;
  margin: 5px;
  height: 23px;
}

.EditorTabs li.selected {
  background-color: rgba(255, 255, 255, 0.493);
  color: var(--onschedDark);
}