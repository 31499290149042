.UserModal .modal-dialog, .UserModal .modal-content {
  max-width: 900px;
  cursor: default;
  border-radius: 10px;
}

.UserModal h3 {
  text-align: center !important;
  font-weight: 500 !important;
  color: var(--onschedDark) !important;
  margin: 10px auto !important;
}

.UserModal h3 {
  font-size: .9rem;
  font-weight: 500;
}

.UserModal h5 {
  color: var(--onschedBlue) !important;
  display: block;
  font-size: .9rem;
  font-weight: 600;
  text-align: center;
}

.UserModal .signout {
  display: block;
  margin: 0 auto;
  width: 100%;
}