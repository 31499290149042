.BookableServicesHeader {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
  text-align: left;
}

.BookableServices .total {
  color: var(--onschedBlue);
  font-weight: 700;
  margin: 0 5px;
  text-transform: uppercase;
}

.BookableServicesHeader th:last-child,
.BookableServicesHeader th:nth-last-child(2) {
  display: none;
}

@media (min-width: 1200px) {
  .BookableServicesHeader th:last-child,
  .BookableServicesHeader th:nth-last-child(2) {
    display: table-cell;
  }
}