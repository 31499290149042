hr {
  max-width: var(--formWidth);
}

.form-sync-container {
  max-width: var(--formWidth);
  margin-bottom: 10rem;
}

.form-image-container{
  max-width: calc(var(--formWidth) + 480px);
}

