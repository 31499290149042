.DataListItem {
  background-color: var(--onschedWhite);
  font-size: .9rem;
  text-align: center;
}

.DataListItem.disabled td {
  cursor: default;
}

.DataListItem:hover td {
  background-color: var(--onschedLightBlue);
  border-color: var(--onschedLightBlue) !important;
}

.DataListItem td {
  text-align: left;
  min-width: 40px;
  cursor: pointer !important;
  padding: 5px 0;
  border-top: 1px solid var(--onschedWhite);
  border-bottom: 1px solid var(--onschedWhite);
}

.DataListItem td:nth-child(3) {
  font-weight: 600;
}

.DataListItem .cells {
  display: none
}

@media (min-width: 992px) {
  .DataListItem .cells {
    display: table-cell !important;
  }
}

.DataListItem .fa-check {
  color: var(--onschedBlue);
}

.DataListItem i {
  font-size: 1.1rem;
}

.DataListItem .fa-trash {
  color: var(--onschedDanger);
  margin: 0 15px;
}

.DataListItem .fa-trash.disabled {
  color: var(--onschedLightGrey);
  cursor: not-allowed;
}

.DataListItem td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; 
}

.DataListItem td:first-child i,
.DataListItem td:first-child:hover span {
  display: none;
}

.DataListItem td:first-child:hover i {
  display: unset;
}

.DataListItem td:first-child:active i {
  font-weight: 900 !important;
}

/* DataListItem radius */
.DataListItem td:nth-child(2),
.DataListItem td:first-child { 
  font-weight: 600;
  color: var(--onschedGrey);
  text-align: center;
  width: 65px; 
  border-left: 1px solid var(--onschedWhite);
}

.DataListItem td:last-child {
  width: 15px;
  border-right: 1px solid var(--onschedWhite);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
