.ParametersList {
  background-color: #8c91a8;
  width: 100%;
  margin-left: unset;
  position: absolute;
  overflow: visible;
  z-index: 9;
  color: var(--onschedDark);
  min-height: 23px;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 2px 15px;
  cursor: pointer;
  border-radius: 8px;
  font-size: .8rem;
  font-weight: 700;
  margin: 35px 5px 5px;
  height: 23px;
  max-width: calc(100% - 40px);
}

@media (min-width: 500px) {
  .ParametersList {
    margin: 5px;
    max-width: 150px;
    margin-left: calc(100% - 205px);
  }
  
  .ParametersList .dropdown-caret {
    margin-left: 115px;
  }
}

@media (min-width: 992px) {
  .ParametersList {
    margin: 5px;
    max-width: 200px;
    margin-left: calc(100% - 550px);
  }
}

@media (min-width: 1415px) {
  .ParametersList {
    margin: 5px;
    max-width: 215px;
    margin-left: 335px !important;
  }
}


.ParametersList.active {
  animation: openDropdown 750ms 1 forwards;
}

.ParametersList .dropdown-caret {
  position: absolute;
  margin-left: calc(100% - 40px);
  font-size: 1.2rem;
  margin-top: -1px;
}

.ParametersList.active .divider {
  width: 100%;
  height: 2px;
  margin: 5px auto 10px auto;
  border-radius: 15px;
  opacity: .2;
}

@keyframes openDropdown {
  0% {height: 0%;}
  100% {height: 100%;}
}