.BookingModal {
  display: none;
  overflow: hidden;
  border: none;
  width: 100%;
  position: fixed;
  z-index: 9999;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 200ms 1 forwards;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.BookingModal.show {
  display: flex !important;
}

.BookingModal .modal-bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #000;
  opacity: .5;
  z-index: 1;
}