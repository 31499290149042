.AddedList {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  margin-top: 20px;
}

.AddedList th {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
}

.AddedList tr:not(:first-of-type) {
  background-color: var(--onschedWhite);
  margin: 5px;
}

.AddedList td {
  cursor: default;
  font-weight: 500;
}

.AddedList td:first-child { border-bottom-left-radius: 6px; width: 65px;  }
.AddedList td:first-child { border-top-left-radius: 6px }
.AddedList td:last-child { border-bottom-right-radius: 6px;  }
.AddedList td:last-child { border-top-right-radius: 6px }

.AddedList td.resource:hover {
  cursor: pointer;
  color: var(--onschedBlue);
}