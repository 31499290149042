.SignInForm {
  max-width: 600px;
  padding: 25px;
  background-color: var(--onschedWhite);
  border-radius: 10px;
  height: 100%;
  margin-bottom: 15px;
}

.SignInForm button {
  width: 100%;
}

.SignInForm button:hover {
  cursor: pointer !important;
}

.SignInForm .forgot {
  padding: 0;
  margin: 15px 0;
  float: right;
  background-color: transparent;
  border: none;
  outline: none;
  width: unset;
  color: var(--onschedBlue);
  font-weight: 500;
  text-align: right;
}
