.WeekDays ul {
  padding-left: 0px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  width: 100%;
}

.WeekDays label {
  margin: 5px 0 10px 5px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedGrey);
}