.ResourcesField {
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.ResourcesField .renderField {
  margin-bottom: 0px !important;
  min-width: 100% !important;
}

.ResourcesField .renderField input {
  padding-left: 35px;
}

.ResourcesField.searching .renderField input {
  width: 100%;
  border-radius: 8px 8px 0 0;
  border-bottom: none !important;
}

.ResourcesField .fa-search {
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
}

.ResourcesField .results {
  padding: 0;
  max-width: calc(100% - 30px);
  list-style-type: none;
  border-top: none !important;
  border-radius: 0 0 8px 8px;
  background-color: var(--onschedWhite);
  position: absolute;
  width: 100%;
  overflow: scroll;
  animation: closeResourcesSearch 200ms 1 forwards;
}

.ResourcesField.searching .results {
  border: 1px solid var(--onschedLightGrey);
  border-color: var(--onschedBlue);
  animation: openResourcesSearch 200ms 1 forwards;
  padding: 5px;
}

.ResourcesField .results li {
  padding-left: 15px;
  font-weight: 500;
  border-radius: 4px;

}

.ResourcesField .results li:hover {
  background-color: var(--onschedLightBlue);
  cursor: pointer;
}

@keyframes openResourcesSearch {
  0% {height: 0px;}
  100% {height: 150px;}
}

@keyframes closeResourcesSearch {
  0% {height: 150px;}
  100% {height: 0px;}
}