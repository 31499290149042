/* @font-face {
  font-family: 'AvenirsNext';
  font-style: normal;
  src: url("https://cdn.public.onsched.com/content/assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
} */

:root {
  --onschedBg        : #f9f9fb;
  --onschedBlue      : #4268ee;
  --onschedLightBlue : #ebebff;
  --onschedDark      : #1B2553;
  --onschedGrey      : #7c7f93;
  --onschedLightGrey : #D8D8DE;
  --onschedPurple    : #1B2553;
  --onschedWhite     : #fff;
  --onschedShadow    : #F5F6F7;
  --onschedSuccess   : rgb(113, 248, 113);
  --onschedDanger    : rgb(229, 66, 66);
  --formWidth        : 688px;
}

:root .darkTheme {
  --onschedBg        : #f6f7f9;
  --onschedBlue      : #4268ee;
  --onschedLightBlue : #ebebff;
  --onschedDark      : #fff;
  --onschedGrey      : #fff;
  --onschedLightGrey : #4268ee;
  --onschedPurple    : #fff;
  --onschedWhite     : #1B2553;
  --onschedShadow    : #4268ee;
  --onschedSuccess   : rgb(25, 214, 25);
  --onschedDanger    : rgb(255, 104, 104);
  --formWidth        : 688px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'AvenirsNext', 'avenir next', 'sans-serif', 'arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align:right;
}

.hide {
  display: none !important;
}

.btn {
  padding: 10px 35px;
  background-color: var(--onschedBlue);
  color: var(--onschedWhite);
  font-size: .9rem !important;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  outline: none !important;
  box-shadow: none;
}

.btn:visited{
  color: var(--onschedWhite);
  box-shadow: none;
}

.btn:hover {
  color: var(--onschedWhite)
}

.btn:focus{
  color: var(--onschedWhite);
  box-shadow: none;
}

.btn:active{
  color: var(--onschedWhite) ;
}

.outlined {
  border: 1px solid var(--onschedBlue);
  background-color: var(--onschedBg);
  cursor: pointer;
  border-color: var(--onschedBlue);
  color: var(--onschedBlue);
}

.outlined:hover {
  background-color: var(--onschedBlue) ;
  color: var(--onschedWhite) ; 
}


.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: var(--onschedBlue);
  color: var(--onschedWhite)
}

.btn-danger {
  background-color: var(--onschedDanger) !important;
  color:var(--onschedWhite);
}

.btn-danger:hover {
  background-color: #ea6969 !important;
}

.btn-flat {
  background-color: var(--onschedLightBlue) !important;
  color: var(--onschedDark);
}
.btn-flat:active, .btn-flat:visited, .btn-flat:focus, .btn-flat:hover {
  color: var(--onschedDark) !important;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  background-color: var(--onschedBlue);
  color: var(--onschedWhite);
  cursor: default;
}

.btn.add::before, .btn.saveBtn::before {
  content: '+';
  position: relative;
  left: -5px;
  
}

img.trash-icon {
  width: 18px;
  margin: 0 10px;
}

.renderField.radio input[type="radio"] {
  margin-right: 20px;
  opacity: 0;
  display: inline;
}

.radio-field {
  max-height: 25px;
}

.renderSelect select {
  display: inline-block;
  margin-bottom: 15px;
  outline: none !important;
  font-weight: 500;
  font-size: .9rem;
  width: 100%;
  padding: 13px 10px 5px;
  border-color: var(--onschedLightGrey);
  background-color: var( --onschedWhite);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
}

.renderSelect.split, .advanceAhead .renderSelect {
  width: 100%;
  display: inline-block;
}

.renderField input {
  width: 100%;
  padding: 10px 10px 5px;
  background-color: var(--onschedWhite);
  outline: none;
  border: 1px solid var(--onschedLightGrey);
  border-radius: 8px;
}

.renderField.renderCheckbox input {
  width: 40px;
  position: relative;
  top: 2px;
}


.renderField.icon i{
  position: absolute;
  margin-top: 40px;
  color: var(--onschedGrey);
  cursor: pointer;
  text-align: center;
  left: 5%;
  margin-left: 8px;
  
} 

.text-right {
  text-align: right !important
}

@media (min-width: 680px) {
  .renderSelect.split, .advanceAhead .renderSelect {
    padding-right: 5px;
  }
  
  .renderSelect.split, .advanceAhead .renderSelect {
    width: 50% !important;
  }

  .renderField input {
    width: calc(100% - 5px);
    padding: 10px 10px 5px;
    background-color: var(--onschedWhite);
    outline: none;
    border: 1px solid var(--onschedLightGrey);
    border-radius: 8px;
  }

  .renderSelect select {
    width: calc(100% - 5px);
  }
}

.renderField.renderRadio input {
  width: 25px;
  display: inline-block;
}

.renderSelect label {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .75rem;
  margin-bottom: 3px;
  margin-left: 7px;
  color: var(--onschedGrey);
}

.renderSelect label::before {
  left: calc(100% - 45px);
  transform: rotate(-45deg);
}

.renderSelect label::after {
  left: calc(100% - 42px);
  transform: rotate(45deg);
}

.renderSelect label::before, .renderSelect label::after {
  content: '';
  width: 2px;
  height: 6px;
  background-color: var(--onschedLightGrey);
  position: absolute;
  margin-top: 36px;
}

.input-radio, .input-checkbox {
  width: 100%;
  display: inline-block;
  font-weight: 450;
  font-size: .9rem;
  color: var(--onschedGrey);
  padding: 15px 0 0 15px;
}

.checkbox-no-padding {
  padding: 15px 0 0 0 !important;
}

.input-checkbox input,
.input-radio input {
  margin-right: 10px;
}

.input-checkbox img {
  position: absolute;
  margin-left: -28px;
  margin-top: 4px;
}

@media (max-width: 991.98px) {
  .hideMobile {
    display: none;
  }
}

.icon {
  width: 18px;
  cursor: pointer;
}


.tooltip-inner {
  background-color: var(--onschedGrey);
}

.tooltip .arrow::before {
  border-bottom-color: transparent;
}


.input-radio input::before, .input-checkbox input::before {
  content: '';
  width: 23px;
  height: 23px;
  background-color: var(--onschedWhite);
  border: 1px solid var(--onschedLightGrey);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -1px;
  top: -1px;
}

.input-radio input:checked::before, .input-checkbox input:checked::before {
  background-image: url('/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 7px;
}

.input-radio input {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  position: relative;
  left: 4px;
  top: 5px;
}

.input-checkbox input::before {
  position: relative;
  left: -6px;
  top: -6px;
}

/* .appcues .apcl-right {
  right: 84px !important;
  bottom: 5px !important;
} */

.emptyList {
  text-align: center;
  margin-bottom: 150px;
}

@media (min-width: 768px) {
  .renderField:not(.fullWidth),
  .renderDropdownField:not(.fullWidth) {
    max-width: 50%;
  }
  
  .renderField.split, 
  .renderDropdownField.split {  
    max-width: 25%;
  }

  .renderField.icon i {
    position: absolute;
    display: block;
    transform: translate(0, 40%);
    top: 50%;
    left: unset;
    pointer-events: none;
    text-align: center;
    font-style: normal;
    margin-left: 15px;
  }
}

.renderPasswordField i {
  position: absolute;
  margin-top: 13px;
  left: unset;
  margin-left: -25px;
  color: var(--onschedGrey);
  cursor: pointer;
}
