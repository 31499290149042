.ToastErrorActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 768px) {
  .ToastErrorActions {
    flex-direction: column;
  }
}

.ToastErrorActions button {
  border: 1px solid var(--onschedWhite);
  background: transparent;
  outline: none;
  color: var(--onschedWhite);
  border-radius: 8px;
  padding: 10px 25px;
  font-weight: 600;
  margin: 5px;
  text-decoration: none;
}

.ToastErrorActions button.loading {
  color: var(--onschedDanger);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ToastErrorActions button:hover {
  background-color: var(--onschedWhite);
  color: var(--onschedDanger);
  cursor: pointer;
}

.ToastErrorActions button.loading:hover {
  color: #fff;
}

.ToastErrorActions button.loading::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid;
  border-color: #fff;
  animation: spinLoader 800ms infinite forwards;
  animation-timing-function: linear; 
  border-left: 5px solid rgba(255, 255, 255, 0.5);
}

.ToastErrorActions button.loading:hover::before {
  border-color: var(--onschedDanger);
  border-left: 5px solid rgba(255, 255, 255, 0.5);
}

@keyframes spinLoader {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
