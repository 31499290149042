.MenuCard {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-weight: 500;
  margin: 5px 0 10px 5px;
  background-color: var(--onschedWhite);
  border-radius: 10px;
  box-shadow: 2px 2px 2px var(--onschedShadow);
}

.MenuCard:hover {
  cursor: pointer;
}

.MenuCard span {
  display: none;
}

.MenuCard:hover span {
  display: block;
}

.MenuCard a:hover .fa-arrow-right {
  color: var(--onschedBlue);
}

.MenuCard a:hover .card-body {
  color: var(--onschedBlue)
}

.MenuCard span:hover {
  color: var(--onschedBlue) !important;
}

.MenuCard a {
  color: var(--onschedDark);
  font-weight: 600;
  font-size: .9rem;
  display: flex;
  align-items: center;
  width: 100%;;
  text-decoration: none;
}

.MenuCard i {
  margin-right: 19px;
  font-size: 19px;
}

.MenuCard .card-body {
  padding: 1rem .75rem;
}

.MenuCard span {
  color: var(--onschedGrey);
  float: right;
}

@media (max-width: 991.98px) {
  .MenuCard, .TestBooking .card {
    margin: 5px 0 !important;
  }
}

@keyframes onboard {
  0% {color: var(--onschedLightGrey); font-size: 19px; margin-right: 19px}
  25% {color: var(--onschedLightGrey); font-size: 17px; margin-right: 21px}
  50% {color: var(--onschedBlue); font-size: 20px; margin-right: 18px}
  50% {color: var(--onschedBlue); font-size: 22px; margin-right: 16px}
  75% {color: var(--onschedBlue); font-size: 20px; margin-right: 18px}
  100% {color: var(--onschedBlue); font-size: 19px; margin-right: 19px}
}