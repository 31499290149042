.VerifyPassword {
  flex-direction: column;
  align-items: center;
  background-color: var(--onschedBg);
  min-height: 100vh;
}

.VerifyPassword .VerifyPasswordNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--topNavHeight);
  width: 100%;
  background-color: var(--onschedShadow);
  margin-bottom: 50px;
}

.VerifyPassword .VerifyPasswordNav img {
  display: inline-block;
  height: auto;
  width: 105px;
  margin-left: 50px;
}
