.sync-container .btn-logo-remove,
.sync-container .btn-logo-remove:focus {
  background-color: transparent !important;
  color: var(--onschedDanger) !important;
  padding: 0;
  opacity: .8;
}

.sync-container .btn-logo-remove:hover {
  color: var(--onschedDark) !important;
}


.sync-container {
  max-width: calc(var(--formWidth) - 40px);
  display: flex;
  align-items: center;

  justify-content: space-around;

}


.form-sync-container p{
  margin-left: 1rem;
}


.sync-container .fa-check {
  color: var(--onschedSuccess);
  opacity: 1;
}

.sync-container .fa-times {
  color: var(--onschedDanger);
  opacity: 1;
}

