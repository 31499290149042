.FormFields {
  max-width: 650px;
}

@media (min-width: 768px) {
  .FormFields .split {
    max-width: 50%;
  }
}

.FormFields .renderField {
  width: 100% !important;
  padding: 0 5px 0 0;
}

.FormFields .renderDropdownField {
  padding: 0 10px 0 0;
}

.FormFields .dynamically-disabled.hide-disabled {
  display: none;
}
