.SideNav {
  min-height: 650px;
  height: calc(100% + 500px);
  width: 100%;
  padding-top: 50px;
  background-color: var(--onschedWhite);
  flex-wrap: nowrap;
  position: absolute;
  z-index: 999;
}

@media (min-width: 992px) {
  .SideNav {
    display: block !important;
    padding-top: 37px;
    position: static;
    animation: expandSideNav 300ms 1 forwards;
  }

  .SideNav.compressed {
    animation: compressSideNav 300ms 1 forwards;
  }

  @keyframes expandSideNav {
    0% {min-width: 95px !important;width: 100%; max-width: 95px;}
    100% {min-width: 250px !important;width: 100%; max-width: 250px;}
  }

  @keyframes compressSideNav {
    0% {min-width: 250px !important;width: 100%; max-width: 250px }
    100% {min-width: 95px !important;width: 100%; max-width: 95px;}
  }
}

.SideNav.show {
  display: none;
}

.SideNav .dropdown-divider {
  border-color: var(--onschedWhite)
}