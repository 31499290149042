.Upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: var(--onschedShadow);
  border: 1px solid var(--onschedLightGrey);
  border-radius: 8px;
  max-width: 320px;
  justify-content: space-evenly;
  padding: 15px;
}

.Image {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 5px 25px 5px 0;
  border-radius: 50%;
  width: 100px;
  background-color: var(--onschedWhite);
  height: 100px;
  overflow: hidden;
  border: 1px solid var(--onschedLightGrey);
}

.Image i {
  color: var(--onschedLightGrey)
}

.Image img {
  width: 100%;
  height: auto;
}

.Upload .FileUploadInput {
  width: 118.98px;
  height: 68px;
  outline: none;
  position: absolute;
  margin-left: 14px;
  margin-top: -30px;
  opacity: 0;
  cursor: pointer;
}

.UploadButtons .btn-logo-remove,
.UploadButtons .btn-logo-remove:focus {
  background-color: transparent !important;
  color: var(--onschedGrey) !important;
  padding: 15px 25px 10px;
  opacity: .8;
}

.UploadButtons .btn-logo-remove:hover {
  color: var(--onschedDark) !important;
}

.UploadButtons .btn-logo-upload {
  padding: 8px 25px;
  margin: 0 15px;
  top: 0;
  box-shadow: 0px 4px 8px 0px var(--onschedLightGrey);
}

.UploadButtons:not(:hover) .btn-logo-upload {
  background-color: var(--onschedWhite) !important;
  color: var(--onschedBlue) !important;
}

.UploadButtons .btn-logo-upload:hover {
  background-color: var(--onschedBlue) !important;
  color: var(--onschedWhite) !important;
  padding: 8px 25px;
  margin: 0 15px;
  top: 0;
  box-shadow: 0px 4px 8px 0px var(--onschedLightGrey);
}

.UploadButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Upload canvas {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
