@media (min-width: 500px) {
  .TemplateHeaderSwitch {
    margin-right: 60px !important;
  }
}

.TemplateHeaderSwitch {
  display: inline-flex;
  padding-left: 35px;
  margin-right: 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedLightGrey);
}

.TemplateHeaderSwitch::before {
  position: absolute;
  content: "";
  margin-left: -35px;
  height: 20px;
  width: 38px;
  background-color: var(--onschedLightGrey);
  border-radius: 50px;
}

.TemplateHeaderSwitch.active::before {
  background-color: var(--onschedWhite);
}

.TemplateHeaderSwitch .toggle {
  position: relative;
  top: 1px;
  width: 18px;
  height: 18px;
  background-color: var(--onschedGrey);
  border-radius: 50%;
  animation: toggleOff 100ms 1 forwards;
}

.TemplateHeaderSwitch.active .toggle {
  animation: toggleOn 100ms 1 forwards;
  background-color: var(--onschedBlue);
}

@keyframes toggleOn {
  0% {left: -17px}
  100% {left: -33px}
}

@keyframes toggleOff {
  0% {left: -33px}
  100% {left: -17px}
}