.EmailTemplate .Header {
  margin-bottom: 15px;
}

.EmailTemplate .template-editor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.EmailTemplate h5 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--onschedDark);
}