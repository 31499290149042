.append {
  height: 38px;
}

.list {
  border-collapse: separate; 
  border-spacing: 0 15px;
  width: 100%;
}

.list th{
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
  text-align: center;
}

.list .cells {
  display: none;
}

@media (min-width: 992px) {
  .list .cells {
    display: table-cell !important;
  }
}

