.ToastError {
  position: fixed;
  text-align: center;
  bottom: 0;
  width: 100%;
  background-color: var(--onschedDanger);
  box-shadow: 0px 0px 6px -2px var(--onschedLightGrey);
  opacity: .95;
  color: var(--onschedWhite);
  z-index: 999999;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-height: 250px;
  animation: slidedown 500ms 1 forwards;
}

.ToastError h1 {
  font-weight: 400;
  font-size: 1.6rem;
}

.ToastError h1 span {
  display: none;
}

.ToastError.active {
  animation: slideup 500ms 1 forwards;
  padding: 15px 30px;
}

.ToastError.reported {
  text-align: center;
  justify-content: center;
}

.ToastError.reported h1 {
  font-size: 1.6rem;
}

@keyframes slideup {
  0%   {}
  100% {height: 100%;}
}

@keyframes slidedown {
  0%   {height: 250px;}
  100% {height: 0px;}
}

@media (min-width:768px) {
  .ToastError {
    flex-direction: row;
    text-align: left;
    max-height: 150px;
  }

  .ToastError h1 span {
    display: unset;
    font-size: 1.4rem;
    position: relative;
    top: -5px;
  }

  @keyframes slidedown {
    0%   {height: 200px;}
    100% {height: 0px;}
  }
}

@media (min-width:992px) {
  .ToastError {
    max-height: 100px;
  }

  @keyframes slidedown {
    0%   {height: 150px;}
    100% {height: 0px;}
  }
}

.ToastError .fa-bug {
  font-size: 0.6em;
  position: relative;
  bottom: 5px;
  opacity: .5;
  margin: 0 20px 0 10px;
  left: 5px;
}