.ForgotPasswordForm {
  width: 100%;
  max-width: 600px;
  padding: 25px;
  background-color: var(--onschedWhite);
  border-radius: 10px;
  height: 100%;
  margin-bottom: 15px;
}

@media (min-width: 600px) {
  .ForgotPasswordForm {
    width: 600px;
  }
}

.ForgotPasswordForm button {
  width: 100%;
  margin-top: 0px;
}

.ForgotPasswordForm button:hover {
  cursor: pointer !important;
}