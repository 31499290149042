.DataList {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.emptyList {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
  margin-top: 150px;
}