.Locations {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.Locations .Secondary table th, .Locations h5 {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
}

.Locations .Secondary table {
  border-collapse: separate; 
  border-spacing: 0 15px;
  width: 100%;
}

.Locations .Company {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.Locations .Company .header {
  margin: 20px 0 -10px;
  display: none;
} 

@media (min-width: 1200px) {
  .Locations .Company {
    margin-bottom: 50px;
  }
  
  .Locations .Company .header {
    display: block;
  } 
}