.ModifyLocation {
  text-align: left;
}

.subtitle {
  margin: 20px 0px;
  color: var(--onschedGrey);
  font-weight: 500;
}

p { 
  font-size: 14px;
}

.text {
  font-size: 14px;
  margin-bottom: 9px;
}
.paragraph {
  max-width: 650px;
}