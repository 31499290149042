.APIDocs {
  display: block;
  width: 100%;
  max-width: 450px;
}

.APIDocs a {
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
  color: var(--onschedBlue);
  text-decoration: none;
}

.APIDocs p {
  font-size: 14px;
}

.APIDocs h5 {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  margin-bottom: 10px;
  color: var(--onschedDark);
}

.APIDocs .APIDocsLink {
  margin-bottom: 25px;
}