.SideNav:hover .ResizeToggle {
  opacity: 1;
}

.ResizeToggle {
  top: 0;
  left: 0px;
  opacity: 0;
  height: 65px;
  cursor: pointer;
  text-align: right;
  position: absolute;
  padding-top: 10px;
  padding-right: 10px;
  display: none;
  animation: expandToggle 300ms 1 forwards;
}

.ResizeToggle.compressed {
  animation: compressToggle 300ms 1 forwards;
}

.ResizeToggle i:hover {
  color: var(--onschedBlue);
}

@keyframes compressToggle {
  0% {width: 250px}
  100% {width: 95px}
}

@keyframes expandToggle {
  0% {width: 95px}
  100% {width: 250px}
}

@media (min-width: 992px) {
  .ResizeToggle {
    display: unset;
  }
}