.Appointments .fa-exchange-alt {
  color: var(--onschedGrey);
  position: relative;
  top: 2px;
  margin: 0 5px;
}

.Appointments .emptyList {
  text-align: center;
  margin-bottom: 150px;
}