.SignIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--onschedBg);
  min-height: 100vh;
}

.SignIn p, .SignIn p a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedGrey);
}

.SignIn p a {
  color: var(--onschedBlue);
}

.SignIn p button {
  color: var(--onschedBlue);
  padding: 0;
  outline: none;
  background-color: transparent;
  border: none;
}