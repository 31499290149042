.DataListHeader {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
  text-align: left;
}

.DataListHeader :first-child {
  text-align: center;
}

.DataListHeader :nth-child(2) {
  padding-left: 65px;
}

.DataListHeader .HeaderItem {
  display: none;
}

@media (min-width: 992px) {
  .DataListHeader .HeaderItem {
    display: table-cell;
  }
}
