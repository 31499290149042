.SettingsFields {
  margin: 18px 0 50px 0;
  max-width: 450px;
  width: 100%;
  display: inline-block;
}

.SettingsFields i {
  position: absolute;
  margin-top: 43px;
  left: unset;
  margin-left: -25px;
  color: var(--onschedGrey);
  cursor: pointer;
}

.SettingsFields i:hover {
  color: var(--onschedBlue);
}

.SettingsFields i.fa-check {
  color: var(--onschedBlue);
}

.SettingsFields .ClientSecret .viewPw {
  margin-top: 14px;
  margin-left: -55px;
}

.reset-button {
  margin-top: 5px;
  margin-bottom: 10px;
}


@media (min-width: 720px) {
  .reset-button {
    margin-top: 29px;
    margin-bottom: 0px;
  }
}
