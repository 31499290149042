.TestBooking {
  color: unset !important;
  text-decoration: none !important;
}

.TestBooking.disabled .card {
  color: var(--onschedGrey) !important;
  cursor: not-allowed !important;
}

.TestBooking .card {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-weight: 500;
  margin: 5px 0 10px 5px;
  font-weight: 600;
  font-size: .9rem;
  background-color: var(--onschedWhite);
  border-radius: 10px;
  box-shadow: 2px 2px 2px var(--onschedShadow);
}

.TestBooking:hover {
  cursor: pointer;
}

.TestBooking span {
  display: none;
}

.TestBooking:hover span {
  display: block;
}

.TestBooking:hover .fa-arrow-right {
  color: var(--onschedBlue);
}

.TestBooking a:hover .card-body {
  color: var(--onschedBlue)
}

.TestBooking:hover {
  color: var(--onschedBlue) !important;
}

.TestBooking a {
  color: var(--onschedDark);
  font-weight: 600;
  font-size: .9rem;
  display: flex;
  align-items: center;
  width: 100%;;
  text-decoration: none;
}

.TestBooking i {
  margin-right: 15px;
}

.TestBooking .card-body {
  padding: 1rem .75rem;
}

.TestBooking span {
  color: var(--onschedGrey);
  float: right;
}

@media (max-width: 991.98px) {
  .TestBooking {
    margin: 5px 0;
  }
}

@keyframes onboard {
  0% {color: var(--onschedLightGrey); font-size: 19px; margin-right: 19px}
  25% {color: var(--onschedLightGrey); font-size: 17px; margin-right: 21px}
  50% {color: var(--onschedBlue); font-size: 20px; margin-right: 18px}
  50% {color: var(--onschedBlue); font-size: 22px; margin-right: 16px}
  75% {color: var(--onschedBlue); font-size: 20px; margin-right: 18px}
  100% {color: var(--onschedBlue); font-size: 19px; margin-right: 19px}
}