/* .WorkWeekCard {
  display: flex;
  flex-wrap: wrap;
}
*/
.WorkWeekCard div {
  max-width: 450px;
}

.WorkWeekCard .displayed {
  background-color: var(--onschedShadow);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--onschedLightGrey);
  color: var(--onschedGrey);
  font-weight: 500;
  min-height: 160px;
  max-width: 400px;
  font-size: .9rem;
}

.WorkWeekCard .displayed {
  margin-bottom: 20px;
}

.WorkWeekCard .fas.fa-exclamation-circle {
  margin-right: 5px;
}