.EventsTab {
  width: 100%;
  margin-top: 20px;
}

.help-tip-title {
  display: flex;
}

.allocation-button {
  margin-top: 15px;
}

.options {
  max-width: 500px;
}

.EventsTab .renderField {
  /* width: unset; */
  margin: 5px 0;  
}