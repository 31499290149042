:root {
  --topNavHeight : 65px;
}

.TopNav {
  height: var(--topNavHeight);
  background-color: var(--onschedShadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0 0;
}

.TopNav .navbar-brand {
  display: none;
  text-align: left;
  height: var(--topNavHeight);
  background: var(--onschedWhite);
  width: 250px;
  border-radius: 0 15px 0 0;
  padding: 0;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: inherit;
  white-space: nowrap;
  flex-wrap: nowrap;
  animation: expandNav 300ms 1 forwards;
}

.TopNav.compressed .navbar-brand {
  animation: compressNav 300ms 1 forwards;
}

@keyframes compressNav {
  0% {width: 250px;}
  100% {width: 95px;}
}

@keyframes expandNav {
  0% {width: 95px;}
  100% {width: 250px;}
}

.TopNav .navbar-brand i {
  color: var(--onschedGrey);
  opacity: 0;
  top: -12px;
}

.TopNav .navbar-brand i:hover {
  color: var(--onschedBlue) !important;
}

.TopNav:hover .navbar-brand i {
  opacity: 1;
}

.TopNav .navbar-brand .fa-compress-alt {
  animation: compressIcon 300ms 1 forwards;
}

@keyframes compressIcon {
  0% {font-size: 1.25rem;left: 0px;}
  50% {font-size: 1.05rem;}
  100% {font-size: 1.25rem;left: 40px}
}

.TopNav .navbar-brand .fa-expand-alt {
  animation: expandIcon 300ms 1 forwards;
}

@keyframes expandIcon {
  0% {font-size: 1.25rem;left: 0px;}
  50% {font-size: 1.45rem;}
  100% {font-size: 1.25rem;left: -25px;}
}


.TopNav .navbar-brand img {
  margin: 27px 33px;
  max-width: 105px;
  width: 100%;
}

.TopNav.compressed .navbar-brand img {
  max-width: 24px;
}
/* 
@media (max-width: 991.98px) {
  .TopNav .navbar-brand {
    background: transparent;
    width: 50px;
  }
  .TopNav .navbar-brand img {
    display: none;
  }
} */

@media (min-width: 992px) {
  .TopNav .navbar-brand {
    display: inline-block;
  }
}

.TopNav .navbar-toggler {
  border: none;
  outline: none;
  padding: .5rem .75rem .25rem;
}

.TopNav .navbar-toggler img {
  width: 22px;
}

.TopNav .navbar-toggler:not(.active) img {
  animation: rotateToggle 200ms 1 forwards;
}

.TopNav .navbar-toggler.active img {
  animation: rotateToggle2 200ms 1 forwards;
}

@keyframes rotateToggle {
  0% {transform: rotate(90deg)}
  100% {transform: rotate(0deg)}
}

@keyframes rotateToggle2 {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(90deg)}
}

.TopNav .SelectedLocation {
  display: flex;
  align-items: center;
}

.TopNav .SelectedLocation .selectedLogo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--onschedBg);
  display: flex;
  align-items: center;
  border: 1px solid var(--onschedLightBlue);
  justify-content: center;
  margin: 0 15px;
}

.TopNav .SelectedLocation .selectedLogo img {
  width: calc(100% + 15px);
  height: auto;
  cursor: pointer;
}

.TopNav .SelectedLocation .selecting {
  position: absolute;
  top: 15px;
  overflow: hidden;
}

.TopNav .SelectedLocation .selecting:first-child {
  opacity: 0;
  animation: fadeIn1 200ms forwards 1;
}

.TopNav .SelectedLocation .selecting:nth-child(2) {
  opacity: 0;
  animation: fadeIn2 200ms 50ms forwards 1;
}

.TopNav .SelectedLocation .selecting:nth-child(3) {
  opacity: 0;
  animation: fadeIn3 200ms 100ms forwards 1;
}

.TopNav .SelectedLocation .selecting:nth-child(4) {
  opacity: 0;
  animation: fadeIn4 200ms 150ms forwards 1;
}

.TopNav .SelectedLocation .selecting:nth-child(5) {
  opacity: 0;
  animation: fadeIn5 200ms 200ms forwards 1;
}

.TopNav .SelectedLocation .icons {
  display: flex;
  flex-direction: row-reverse;
}

@keyframes fadeIn1 {
  0% {opacity: 0; right: 16px;}
  100% {opacity: 1;right: 65px}
}
@keyframes fadeIn2 {
  0% {opacity: 0;; right: 16px;}
  100% {opacity: 1;right: 115px}
}
@keyframes fadeIn3 {
  0% {opacity: 0;; right: 16px;}
  100% {opacity: 1;right: 165px}
}
@keyframes fadeIn4 {
  0% {opacity: 0;; right: 16px;}
  100% {opacity: 1;right: 215px}
}
@keyframes fadeIn5 {
  0% {opacity: 0;; right: 16px;}
  100% {opacity: 1;right: 270px}
}

.TopNav .SelectedLocation i {
  color: var(--onschedBlue)
}

.TopNav .SelectedLocation a:not(.selecting) {
  font-size: .8rem;
  color: var(--onschedGrey);
  font-weight: 600;
  border-radius: 6px;
  padding: 7px 12px;
  margin: 0 14px 0 10px;
  border: 1px solid var(--onschedLightGrey);
  background-color: var(--onschedBg);
  text-decoration: none;
}

@media (max-width: 500px) {
  .TopNav .SelectedLocation a {
    display: none;
  }
}

.TopNav #locationLoading {
  animation: pulse 1s infinite forwards;
  width: 14px;
  height: 14px;
  position: relative;
  border-radius: 50%;
  margin-right: 25px;
  background-color: var(--onschedPurple)
}

@keyframes pulse {
  0% {opacity: 0;width: 0;height: 0;margin-right: 32px}
  50% {opacity: .8;width: 14px;height: 14px;margin-right: 25px}
  100% {opacity: 0}
}

.TopNav .initials {
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--onschedWhite);
  font-weight: 600;
  margin: 0 15px;
}

.TopNav .MainNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}