.OnSchedJS {
  padding: 5px 15px;
  background-color: var(--onschedWhite);
  overflow: scroll;
  z-index: 999999;
  width: 100%;
  height: 100%;
  animation: openModal 300ms 1 forwards;
}

@keyframes openModal {
  0% {opacity: 0;margin-top: -200px;}
  100% {opacity: 1;margin-top: 0px;}
}

@media(min-width: 992px) {
  .OnSchedJS {
    padding: 10px 50px;
    border-radius: 20px;
    max-height: 494px;
    max-width: 800px;
  }
}
/* 
.OnSchedJS h5 {
  text-align: left;
  margin: 10px 0 0 0;
  cursor: default;
}

.OnSchedJS .onsched-search-form {
  margin-bottom: 0;
}

.OnSchedJS .onsched-circle-icon {
  position: relative;
  top: 8px;
  cursor: default;
  font-family: 'AvenirsNext', 'avenir next', 'sans-serif';
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--onschedBlue);
  font-weight: 700;
  padding-top: 5px;
}

.OnSchedJS .onsched-available-times-header .date-selected .dom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  font-weight: 700;
}

.OnSchedJS .onsched-available-times-header {
  width: 260px;
  justify-content: space-between;
  align-items: flex-start;
}

.OnSchedJS .onsched-popup {
  height: 100%;
  width: 100%;
  position: relative;
  top: -15px;
}

.OnSchedJS .onsched-table .info-col .list-item-description {
  cursor: default;
}

.OnSchedJS .onsched-col {
  align-items: center;
}

.OnSchedJS .onsched-list {
  width: 100%;
}

.OnSchedJS .onsched-table {
  text-align: left;
}

.OnSchedJS .onsched-table {
  font-family: 'AvenirsNext', 'avenir next', 'sans-serif';
  font-weight: 600;
}

.OnSchedJS .onsched-table a, .OnSchedJS .onsched-table a:hover {
  color: var(--onschedBlue);
  text-decoration: none;
}

.OnSchedJS .onsched-chip {
  font-weight: 600;
  text-decoration: none;
  background-color: var(--onschedLightGrey);
  color: var(--onschedDark);
}

.OnSchedJS .onsched-chip:hover {
  color: var(--onschedBlue);
  box-shadow: 0 8px 17px 0 var(--onschedShadow), 0 6px 20px 0 var(--onschedShadow);
}

.OnSchedJS .onsched-container .month-next:not(:disabled):hover,
.OnSchedJS .onsched-container .month-prev:not(:disabled):hover {
  color: var(--onschedBlue);
}

.OnSchedJS .onsched-calendar-col .day {
  outline: none;
}

.OnSchedJS .onsched-calendar-col .day:hover:not(:disabled) {
  background-color: var(--onschedLightGrey);
}

.OnSchedJS .onsched-calendar-col .day:not(:disabled) {
  font-weight: 600;
  color: var(--onschedDark);
}

.OnSchedJS .onsched-calendar-col .day.selected {
  background-color: var(--onschedLightBlue);
  color: var(--onschedBlue);
}

.OnSchedJS #loading {
  display: none;
}

.OnSchedJS.loading #loading {
  display: block !important;
}

.OnSchedJS.loading #search,
.OnSchedJS.loading #locations,
.OnSchedJS.loading #services,
.OnSchedJS.loading #resources,
.OnSchedJS.loading #availability {
  display: none !important;
}

.OnSchedJS .onsched-popup-shadow {
  background: unset !important;
}
 */
