.EditEvent .renderField {
  width: unset;
  margin: 5px 0;
}

.EditEvent .repeat-frequency {
  padding-top: 35px;
  padding: 35px 0 0 5px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedGrey);
}

.EditEvent .event-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}