.DomainChecking {
  max-width: 450px;
  margin-bottom: 50px;
}

.DomainChecking p {
  font-size: 14px;
}

.DomainChecking .fa-check {
  color: var(--onschedBlue);
}

.DomainChecking button {
  margin: 15px 15px 15px 0;
}

.DomainChecking .fa-trash-alt {
  color: var(--onschedDanger);
}

.DomainChecking .ExistingDomainList {
  padding-left: 0;
}

.DomainChecking .ExistingDomainList .ExistingDomainItem {
  display: flex;
  align-items: center;
}

.DomainChecking .ExistingDomainList .ExistingDomainItem i {
  margin: 20px 0 0 15px;
  cursor: pointer;
}