.UpdateWeek .optionsRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.UpdateWeek .renderField,
.UpdateWeek .renderField input,
.UpdateWeek .renderField select {
  width: unset !important;
  text-align: center;
}

.UpdateWeek .renderField:first-of-type select {
  text-transform: capitalize;
}

.UpdateWeek .renderField {
  margin: 5px;
}

.UpdateWeek .renderField label {
  display: block;
}

.UpdateWeek .update-week-checkbox {
  position: relative;
  top: 5px;
  left: 25px;
  max-width: 100px;
}