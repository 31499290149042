.HelpTip {
  position: relative;
  top: -4px;
  margin-left: 5px;
  display: inline-block;
}

.HelpTip i {
  color: var(--onschedGrey);
  opacity: .7;
  font-size: .8rem;
  cursor: pointer;
}

.error .HelpTip i {
  color: var(--onschedDanger);
  opacity: 1;
}

.help .tooltip-inner {
  font-size: .9rem !important;
  width: 100%;
  text-align: left;
  padding: 15px;
}
