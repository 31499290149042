
.MasterTemplate {
  font-family:"Avenir W01",avenir,helvetica neue,Arial,sans-serif;
  margin: 16px 0;
  margin:0;
  padding:0;
}

.MasterTemplate .logo {
  height: 30px;
  margin: 15px auto;
}

.MasterTemplate a:visited{
  color:none;
}


.MasterTemplate a:visited {
  text-decoration: none;
  border: none;
}

.MasterTemplate .separator hr {margin:0;}

.MasterTemplate .footer {
  padding-top:30px;
  padding-bottom:30px;
}

.MasterTemplate .email-body {
  background-color: #F1F2F4;
  color: #9a9a9a;
  width: 100%;
  font-family: Avenir W01,avenir,helvetica neue,Arial,sans-serif;
}
.MasterTemplate .email-logo {
  width: 100%;
  border-collapse: separate;
}
.MasterTemplate .email-logo.top {
  border-spacing: 0px;
}
.MasterTemplate .email-logo.bottom {
  border-spacing: 0px;
}
.MasterTemplate .email-logo td {
  text-align:center;
  /*padding: 16px 0;*/
}

.MasterTemplate .email-panel {
  background-color: #1A2653;
  color: #fff;
  width: 100%;
  height:120px;
  border-collapse: collapse;
}
.MasterTemplate .email-panel.top {
  border-radius: 12px 12px 0px 0px;
  -moz-border-radius: 12px 12px 0px 0px;
  -webkit-border-radius: 12px 12px 0px 0px;
}
.MasterTemplate .email-panel.bottom {
  border-radius: 0 0 12px 12px;
  -moz-border-radius: 0 0 12px 12px;
  -webkit-border-radius: 0 0 12px 12px;
}
.MasterTemplate .email-panel td {
  text-align:center;
}
.MasterTemplate .email-panel.top td {
  border-radius: 12px 12px 0px 0px;
  -moz-border-radius: 12px 12px 0px 0px;
  -webkit-border-radius: 12px 12px 0px 0px;
}
.MasterTemplate .email-panel.bottom td {
  border-radius: 0 0 12px 12px;
  -moz-border-radius: 0 0 12px 12px;
  -webkit-border-radius: 0 0 12px 12px;
}
.MasterTemplate .email-content {
  background-color:#fff;
  color:#6e6e6e;
  border-collapse: collapse;
}
.MasterTemplate .email-content td {
  padding: 20px;
  /*text-align:center;*/
}
.MasterTemplate .email-content td.separator {
  padding: 0 20px;
}
.MasterTemplate .email-footer {
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 1px;
  width: 100%;
  max-width: 439px;
  border-collapse:collapse;
  margin-top:16px;
}

.MasterTemplate .contactUs {
  width:50%;
  display:inline-block;
  padding-top: 15px;
  padding-bottom:15px;
}
.MasterTemplate .contactUs .icons {
  display: inline-block;
  margin-left: 20px;
}
.contactUs .icons img{
  width: 40px;
  display: inline-block;
  max-width: 40px;
}
.MasterTemplate .contactUs .contactInfo {
  display: inline-block;
  margin: auto 30px auto 20px;
  text-align: left;
}
.MasterTemplate .contactUs .contactInfo h5 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}
.MasterTemplate .contactUs .contactInfo p {
  margin-top: 5px;
}
.MasterTemplate .contactInfo h5 {
  font-weight: 200 !important;
}
.MasterTemplate .contactInfo a {
  font-weight: 200 !important;
}
