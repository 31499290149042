.NewDomainList {
  list-style-type: none;
  padding-left: 0;
}

.NewDomainList .DomainItem {
  display: flex;
  align-items: center;
}

.NewDomainList .DomainItem i {
  margin: 20px 0 0 15px;
  cursor: pointer;
}

.DomainChecking.fa-trash-alt {
  color: var(--onschedDanger);
}

.NewDomainList .add-domain {
  background-color: transparent !important;
  color: var(--onschedBlue);
  padding: 5px;
}

.NewDomainList .add-domain:hover, .NewDomainList .add-domain:focus {
  color: var(--onschedBlue) !important;
}