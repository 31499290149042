.CompanySelect {
  background-color: var(--onschedBg);
  display: flex;
  align-items: center;

  flex-direction: column;
  min-height: 100vh;
}

.CompanySelect .Pages {
  padding: 0 15px;
  margin-top: 15px;
}

.CompanySelect .Pages .pagination {
  margin-bottom: 0;
}

.CompanySelect .DataList {
  max-width: 1200px;
  padding: 0 15px 15px;
}

.CompanySelect .DataList .DataListItem {
  cursor: pointer;
}

.CompanySelect .DataListItem td {
  border-top: 1px solid var(--onschedWhite);
  border-bottom: 1px solid var(--onschedWhite);
}
.CompanySelect .DataListItem td:first-child {
  border-left: 1px solid var(--onschedWhite);
}
.CompanySelect .DataListItem td:last-child {
  border-right: 1px solid var(--onschedWhite);
}

.CompanySelect .DataListItem:hover td {
  border-top: 1px solid var(--onschedBlue);
  border-bottom: 1px solid var(--onschedBlue);
}
.CompanySelect .DataListItem:hover td:first-child {
  border-left: 1px solid var(--onschedBlue);
}
.CompanySelect .DataListItem:hover td:last-child {
  border-right: 1px solid var(--onschedBlue);
}
