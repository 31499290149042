.SignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--onschedShadow);
  height: 100vh;
}

.SignUp form {
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  border-radius: 10px;
  max-width: 600px;
  animation: fadeIn 300ms 1 forwards;
}

@media (min-width: 992px) {
  .SignUp form .email {
    width: calc(100% - 10px);
  }
}

.SignUp form .terms {
  text-align: left;
  margin: 0 10px 10px;
  font-weight: 500;
}

.SignUp p, .SignUp p button, .SignUp form .terms {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedGrey);
}

.SignUp p button, .SignUp form .terms a {
  color: var(--onschedBlue);
  text-decoration: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
}

.FailedMessage {
  font-weight: 500;
  margin: 15px 0;
  animation: rotate 2s 1 forwards;
}

.SignUp span {
    margin-left: 2px;
    /* color: #fd7171; */
    color: var(--onschedDanger);
    text-transform: uppercase;
    font-size: .7rem;
}