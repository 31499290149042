.PreviewSizes {
  display: flex;
  position: absolute;
  margin-top: -36px;
  right: 20px;
}

.PreviewSizes i {
  margin: 5px;
  padding: 2px;
  color: var(--onschedDark);
  font-size: 1.3rem;
  opacity: .4;
}

.PreviewSizes i:hover {
  cursor: pointer;
}

.PreviewSizes i.selected {
  color: var(--onschedDark);
  border-radius: 4px;
  opacity: 1;
}