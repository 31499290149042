.TimeOffCard {
  width: 100%;
  margin-top: 30px;
}

.TimeOffCard .options {
  max-width: 500px;
}

.TimeOffCard .Loading {
  height: 150px;
}

.TimeOffCard .WeekDays {
  margin-bottom: 25px;
}

.TimeOffCard .renderField {
  width: unset;
  min-width: 157px;
  margin: 5px 0;
}

.TimeOffCard .block-button {
  margin: 15px 0px 40px 0px;

}

.help-tip-title {
  display: flex;
}

.TimeOffCard .renderField.D::after,
.TimeOffCard .renderField.W::after,
.TimeOffCard .renderField.M::after {
  margin-top: 7px;
  margin-left: -65px;
  font-weight: 500;
  font-size: .9rem;
  position: absolute;
}

.TimeOffCard .renderField.D::after        { content : 'day'; }
.TimeOffCard .renderField.D.plural::after { content : 'days'; }
.TimeOffCard .renderField.W::after        { content : 'week'; }
.TimeOffCard .renderField.W.plural::after { content : 'weeks'; }
.TimeOffCard .renderField.M::after        { content : 'month'; }
.TimeOffCard .renderField.M.plural::after { content : 'months'; }

.TimeOffCard .DataList {
  margin-top: 25px;
}