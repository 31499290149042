.ForgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--onschedBg);
  min-height: 100vh;
}

.ForgotPassword .ForgotPasswordNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--topNavHeight);
  width: 100%;
  background-color: var(--onschedShadow);
  margin-bottom: 50px;
}

.ForgotPassword .ForgotPasswordNav img {
  display: inline-block;
  height: auto;
  width: 105px;
  margin-left: 50px;
}

.ForgotPassword p, .ForgotPassword p button {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedGrey);
}

.ForgotPassword p a {
  color: var(--onschedBlue);
  display: inline-block;
  width: 55px;
  text-decoration: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  border: none;
}