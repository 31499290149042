.BookableServiceItem.linked .bookable i {
  animation: bookableService 800ms 1 forwards;
}

.BookableServiceItem:not(.linked) .bookable i {
  animation: unbookableService 200ms 1 forwards;
}

@keyframes bookableService {
  0% {color: var(--onschedLightGrey); font-size: 15px; margin-right: 15px}
  25% {color: var(--onschedLightGrey); font-size: 13px; margin-right: 17px}
  50% {color: var(--onschedBlue); font-size: 16px; margin-right: 14px}
  50% {color: var(--onschedBlue); font-size: 18px; margin-right: 12px}
  75% {color: var(--onschedBlue); font-size: 16px; margin-right: 14px}
  100% {color: var(--onschedBlue); font-size: 15px; margin-right: 15px}
}

@keyframes unbookableService {
  0%{color: var(--onschedBlue);}
  100%{color: var(--onschedLightGrey);}
}

.BookableServiceItem .bookable {
  padding-left: 15px;
  background-color: transparent;
  border: none !important;
  color: var(--onschedLightGrey);
  width: 60px;
}

.BookableServiceItem td:not(.bookable) {
  order: -1;
}

.BookableServiceItem:hover td:not(.bookable) {
  background-color: var(--onschedLightBlue);
  border-color: var(--onschedLightBlue) !important;
}

.BookableServiceItem td {
  text-align: left;
  min-width: 40px;
  cursor: pointer !important;
  padding: 5px 0;
  background-color: var(--onschedWhite);
  border-top: 1px solid var(--onschedWhite);
  border-bottom: 1px solid var(--onschedWhite);
}

.BookableServiceItem td:nth-child(2) {
  width: 65px; 
}

.BookableServiceItem td:nth-child(3) {
  font-weight: 600;
}

.BookableServiceItem .cells {
  display: none
}

@media (min-width: 992px) {
  .BookableServiceItem .cells {
    display: table-cell !important;
  }
}

.BookableServiceItem .fa-check {
  color: var(--onschedBlue);
}

.BookableServiceItem i {
  font-size: 1.1rem;
}

.BookableServiceItem .fa-trash {
  color: var(--onschedDanger);
  margin: 0 15px;
}

.BookableServiceItem .fa-trash.disabled {
  color: var(--onschedLightGrey);
  cursor: not-allowed;
}

/* DataListItem radius */
.BookableServiceItem td:nth-child(2) { 
  border-left: 1px solid var(--onschedWhite);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; 
}

.BookableServiceItem td:last-child {
  width: 15px;
  border-right: 1px solid var(--onschedWhite);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 15px;
}

.BookableServiceItem td:nth-last-child(3) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}



.BookableServiceItem td:last-child,
.BookableServiceItem td:nth-last-child(2) {
  display: none;
}

@media (min-width: 1200px) {
  .BookableServiceItem td:last-child,
  .BookableServiceItem td:nth-last-child(2) {
    display: table-cell;
  }

  .BookableServiceItem .bookable {
    width: 80px;
  }

  .BookableServiceItem td:nth-last-child(3) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}