.NewPasswordForm {
  width: 100%;
  max-width: 600px;
  padding: 25px;
  background-color: var(--onschedWhite);
  border-radius: 10px;
  height: 100%;
  margin-bottom: 15px;
}

@media (min-width: 600px) {
  .NewPasswordForm {
    width: 600px;
  }
}

.NewPasswordForm button {
  width: 100%;
  margin-top: 0px;
}

.NewPasswordForm button:hover {
  cursor: pointer !important;
}


.NewPasswordForm .viewPw {
  position: absolute;
  left: unset;
  color: var(--onschedGrey);
  cursor: pointer;
  margin-top: 14px;
  margin-left: -40px;
}


.NewPasswordForm .renderField:last-of-type {
  margin-bottom: 25px;
}

.NewPasswordForm .renderField .error {
  color: var(--onschedDanger);
  text-transform: uppercase;
  font-size: .7rem;
  font-weight: 600;
}