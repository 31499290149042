.MasterTemplateHeader {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

@media (min-width: 500px) {
  .MasterTemplateHeader h5 {
    margin-right: 60px;
  }
}