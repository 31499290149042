.list-group-item .active{
  background-color: #f9f9fb;
}

.list-group-item {
  border-bottom: 0.5px;
}

p {
  margin-bottom: 0px;
}

.label {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .75rem;
  margin-bottom: 3px;
  margin-left: 7px;
  color: var(--onschedGrey);
}

.green {
  color: green;
}

.red {
  color: red;
}
