.CreateModal .modal-dialog {
  margin-top: 10vh;
  max-width: 400px;
}

.CreateModal .modal-header, .CreateModal .modal-body, .CreateModal .modal-footer {
  padding: .8rem 1.5rem !important;
}

.CreateModal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
  font-weight: 500;
  font-size: 1.4rem;
  justify-content: center;
}

.CreateModal .modal-content {
  border-radius: 20px;
  border: none;
}

.CreateModal .modal-footer {
  border-top: none;
  padding-top: 0;
}