.CompanySearch {
  padding: 0 15px 0;
  width: 100%;
  margin: 15px auto;
  max-width: 1200px;
}


.CompanySearch input {
  z-index: 1000;
  height: 36px;
  outline: none !important;
  padding: 5px 10px 5px 35px !important;
  color: var(--onschedGrey);
  font-weight: 500;
  border: 1px solid var(--onschedLightGrey);
  border-radius: 50px !important;
  box-shadow: 0px 0px 8px var(--onschedShadow) !important;
  background-color: #fff !important;
}

.CompanySearch input:focus {
  border-color: var(--onschedBlue);
}

.CompanySearch .searchIcon {
  position: absolute;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 4px;
  color: var(--onschedDark);
  z-index: 2;
  border: none;
  outline: none;
  background-color: transparent;
}

.CompanySearch .searchIcon:hover {
  color: var(--onschedBlue)
}
