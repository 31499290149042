.overlay { 
  position: fixed;
  bottom: 15px;
  width: calc(100vw - 40%);
  background-color: var(--onschedWhite);
  border-radius: 5px;
  box-shadow: 0px 0px 6px -2px var(--onschedLightGrey);
  z-index: 999998;
  padding: 0.75rem;
  animation: slidedown 500ms 1 forwards;
  display: flex;
  align-items: center;
  visibility: hidden;
}

.overlay p {
  margin: auto;
  color: var(--onschedGrey)
}

.overlay.active {
  animation: slideup 500ms 1 forwards;
  visibility: visible;
}

@keyframes slideup {
  0%   {bottom: -100px; visibility: hidden;}
  100% {bottom: 15px}
}

@keyframes slidedown {
  0%   {bottom: 15px;}
  100% {bottom: -100px; visibility: hidden;}
}

@media screen and (max-width: 768px) {
  .overlay{
    width: calc(100vw - 20%);
    left: auto;
  }
  
  .overlay p {
    padding: 5px;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1280px) {
  .overlay p {
    padding: 5px;
  }
}