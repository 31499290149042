.CodePreview {
  width: 100%;
}

@media (min-width: 1415px) {
  .CodePreview {
    max-width: 50%;
  }
}

.CodePreview .preview {
  border-radius: 8px;
  border: 1px solid var(--onschedLightGrey);
  min-height: 600px;
  padding: 25px;
}