.CompanySelectHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CompanySelectHeader .SearchPaging {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CompanySelectHeader .SearchPaging .Pages {
  position: absolute;
  right: 0;
  top: 110px;
}

@media (min-width: 500px) {
  .CompanySelectHeader .SearchPaging .Pages {
    position: unset !important;
    right: unset !important;
    top: unset !important;
  }
}

.CompanySelectHeader .CompaniesNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--topNavHeight);
  width: 100%;
  max-width: 1200px;
  background-color: var(--onschedShadow);
}

.CompanySelectHeader .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 25px 0 0 25px;
}

.CompanySelectHeader .CompaniesNav img {
  display: inline-block;
  height: auto;
  cursor: pointer;
  width: 105px;
  margin-left: 15px;
}