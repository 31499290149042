.ParameterItem {
  margin-bottom: 5px;
}

.ParameterItem:not(:first-of-type):hover {
  color: var(--onschedWhite);
}

.ParametersList:not(.active) .ParameterItem:first-of-type {
  position: absolute;
}

.ParametersList:not(.active) .ParameterItem:not(:first-of-type) {
  display: none;
}