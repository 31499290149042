.Loading {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 9999;
}

.Loading img {
  max-width: 200px;
}
