.Resources {
  width: 100%;
}

@media (max-width: 767.98px) {
  .Resources .Create {
    position: fixed;
    width: 100%;
    background: var(--onschedShadow);
    border-radius: 4px;
    bottom: 0;
    padding: 15px 0px;
    z-index: 9;
  }

  .Resources .Create .col {
    margin-bottom: 0 !important;
  }

  .Resources .Create .add {
    width: 100%;
  }
}

.Resources .Create .col {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.Resources .Create .add {
  margin-left: auto;
}

.Resources h4 {
  text-align: left;
}

.Resources table {
  border-collapse: separate; 
  border-spacing: 0 15px;
}

.Resources table tr th {
  text-align: left;
}

.Resources table tr th h5 {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
}

.Resources table tr td a {
  text-decoration: none;
  width: 100%;
  color: var(--onschedDark);
  font-weight: 500;
}

.Resources table tr td {
  text-align: left;
  cursor: default;
  background-color: var(--onschedWhite);
  padding: 6px 0;
}

.Resources td:first-child { border-top-left-radius: 6px; width: 50px; }
.Resources.selected td:first-child { border-left: 6px solid var(--onschedBlue); }
.Resources td:last-child { border-top-right-radius: 6px; }
.Resources td:first-child { border-bottom-left-radius: 6px; }
.Resources td:last-child { border-bottom-right-radius: 6px; }