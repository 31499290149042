
.MasterTemplateOptions {
  border-radius: 0 0 8px 8px;
  background-color: var(--onschedDark);
  padding: 15px;
  margin-bottom: 2em;
}

@media (min-width: 500px) {
  .TemplateHeaderOptions h5 {
    margin-right: 55px !important;
  }
}

.MasterTemplateOptions h5 {
  color: var(--onschedLightGrey) !important;
  margin-bottom: 5px;
  min-width: 96px;
}

.MasterTemplateOptions .renderField {
  max-width: 120px;
  margin: 0 10px 25px 10px;
}

.MasterTemplateOptions .renderField .integer-field {
  max-width: 75px;
}

.MasterTemplateOptions label {
  color: var(--onschedLightGrey) !important;
  font-weight: 700 !important;
  margin-left: 0px !important;
  opacity: .7;
}

.MasterTemplateOptions .template-warning i {
  color: orange;
  margin-right: 5px;
}

.MasterTemplateOptions .template-warning {
  color: var(--onschedLightGrey);
  opacity: .7;
  display: inline;
  font-weight: 600;
  font-size: .9rem;
  position: relative;
  top: -10px;
}