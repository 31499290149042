.ModifyResource .notifications {
  margin-left: 15px;
  display    : inline-block;
  margin-top : 10px;
}

.ModifyResource .cal-sync {
  display : block;
  top     : 15px;
}

label .input-checkbox {
  padding: 15px 0px 0px 0px;
}

.notification-label {
  display: inline !important;
}

@media (min-width: 768px) {
  .ModifyResource .notifications {
    position: relative;
  }
  
  .ModifyResource .cal-sync {
    position: relative;
    top: -23px;
  }
}
