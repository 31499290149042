.SignUpNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  width: 100%;
  background-color: var(--onschedbg);
  margin-bottom: 50px;
}

.SignUpNav img {
  display: inline-block;
  height: auto;
  width: 105px;
  margin-left: 50px;
}