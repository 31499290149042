.Appointments .fa-exchange-alt {
  color: var(--onschedGrey);
  position: relative;
  top: 2px;
  margin: 0 10px !important;
}

.total {
  font-weight: 600;
  color: var(--onschedGrey);
  font-size: .75rem;
  text-transform: uppercase;
}