.card-row {
  padding: 0 15px;
  font-weight: 500;
  margin: 15px 0;
  border-radius: 10px;
  background-color: var(--onschedWhite);
}

.card-row input {
  border-width:0px;
  border:none;
  min-width: 250px;
}
.card-row input:focus {
  outline:none
}

.card-row td {
  padding: 5px 0;
  cursor: pointer;
  border-top: 1px solid var(--onschedWhite) ;
  border-bottom: 1px solid var(--onschedWhite) ;
}

.card-row:hover td {
  border-color: var(--onschedLightBlue) !important;
  background-color: var(--onschedLightBlue) !important;
}

.card-row td:first-child,
.card-row td:nth-child(2) { 
  border-left: 1px solid var(--onschedWhite);
  width: 65px;
  font-weight: 600;
  color: var(--onschedGrey);
  text-align: center;
}

.card-row td:first-child i,
.card-row td:first-child:hover span {
  display: none;
}

.card-row td:first-child:hover i {
  display: unset;
}

.card-row td:first-child:active i {
  font-weight: 900 !important;
}

.card-row.selected td:first-child { 
  border-left: 1px solid var(--onschedBlue) !important; 
}

.card-row.selected td:last-child { 
  border-right: 1px solid var(--onschedBlue) !important; 
}

.card-row.selected td {
  border-top: 1px solid var(--onschedBlue) !important;
  border-bottom: 1px solid var(--onschedBlue) !important;
}

.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 45px;
}

.action .edit {
  color: var(--onschedBlue);
}

.action .delete {
  color: var(--onschedDanger);
  padding-right: 5px;
}

.card-row td:last-child { 
  border-top-right-radius: 6px; 
  border-right: 1px solid var(--onschedWhite);
  width: 15px; 
}

.card-row td:first-child { 
  border-bottom-left-radius: 6px; 
  border-top-left-radius: 6px; 
}

.card-row td:last-child { 
  border-bottom-right-radius: 6px; 
}
