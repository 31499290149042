@media (min-width: 1200px) {
  .CompanyCard .Card {
    flex-direction: column !important;
    padding: 40px 15px !important;
  }

  .CompanyCard .LogoContainer {
    margin: 0 auto 15px;
  }
}

.CompanyCard {
  display: flex;
  flex-direction: column;
}

.CompanyCard .Card {
  padding: 15px;
  font-weight: 500;
  margin: 25px 0 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  max-width: 500px;
  border-radius: 8px;
  text-align: center;
  background-color: var(--onschedWhite);
  box-shadow: 2px 2px 2px var(--onschedShadow);
  cursor: default;
  word-break: break-all;
}

.CompanyCard h5 {
  text-align: center;
  font-size: .9rem;
  font-weight: 600;
  margin: 20px 0 10px;
  min-height: unset !important;
}

.CompanyCard p {
  font-size: .8rem;
  color: var(--onschedGrey);
  margin-top: 15px;
  margin-bottom: 0;
}

.CompanyCard .LogoContainer img {
  width: 100%;
  height: auto;
}

.CompanyCard i {
  color: var( --onschedGrey);
  font-weight: 200;
}

.CompanyCard .LogoContainer {
  overflow: hidden;
  width: 100%;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CompanyCard .Logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: var(--onschedLightGrey);
}
