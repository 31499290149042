.App {
  text-align: center;
}

.renderField {
  display: inline-block;
  text-align: left;
  width: 100%;
  margin: 5px 0;
}

.renderField label {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  margin-bottom: 3px;
  margin-left: 7px;
  color: var(--onschedGrey);
}

.renderField input::-webkit-input-placeholder {
  color: var(--onschedGrey);
}

.renderField input:-ms-input-placeholder {
  color: var(--onschedGrey);
}

.renderField input::placeholder {
  color: var(--onschedGrey);
}

.renderField select {
  width: 100%;
  outline: none;
  background-color: var(--onschedWhite);
  border: 1px solid var(--onschedLightGrey);
  position: relative;
  top: -3px;
  display: inline-block;
  height: 41px;
  border-radius: 8px;
  padding: 0 5px;
}

.renderField select:focus, .renderField input:focus {
  border: 1px solid var(--onschedBlue);
}

.renderField.error input, 
.renderField.error select, 
.renderField.error textarea {
  border-color: var(--onschedDanger) !important;
}

.renderField.fullWidth {
  width: 100% !important;
  margin-left: 0 !important;
}

.renderField.phoneField:hover {
  color: var(--osBlue);
}
/* 
.renderField.phoneField::after {
  content: 'A';
  cursor: pointer;
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: -26px;
  margin-top: 3px;
  font-size: 1.2rem;
  font-weight: 500;
} */

.renderField.phoneField .clear-formatting-icon span {
  display: none;
}

.renderField.phoneField:hover .clear-formatting-icon:hover span {
  display: block !important;
  position: absolute;
  margin-left: -145px;
  margin-top: -20px;
  color: #fff;
  font-family: 'AvenirsNext', 'avenir next', sans-serif;
  font-weight: 500;
  background: #8c91a8;
  padding: 2px 10px;
  border-radius: 12px;
}

.renderField.phoneField .clear-formatting-icon {
  display: inline-block;
  cursor: pointer;
  width: 30px;
  text-align: right;
}

/* underline row text area css

.textArea {
  width: 100%;
  border: none;
  margin-top: 6px;
  padding: 0 5px;
  resize: none;
  outline: none;
  line-height: 31px;
  background-image: linear-gradient(transparent, transparent 29px, var(--onschedShadow) 0px);
  background-size: 100% 31px;
  scroll-behavior: smooth;
  overflow: hidden;
}

*/

.textArea {
  width: 100%;
  border: none;
  margin-top: 6px;
  min-height: 48px;
  padding: 10px 10px 5px;
  resize: none;
  outline: none;
  border: 1px solid var(--onschedShadow);
  border-radius: 8px;
  line-height: 31px;
  background-size: 100% 31px;
  scroll-behavior: smooth;
  overflow: hidden;
  border: 1px solid var(--onschedLightGrey);
}

.textArea::-webkit-input-placeholder {
  color: var(--onschedGrey)
}

/* editCard tabs */

.nav-tabs {
  background-color: transparent !important;
  margin-bottom: 15px;
}

.editCard  {
  margin: 10px 0;
  text-align: left;
  min-height: 250px;
}

.editCard h5 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .5px;
}

.editCard p {
  color: var(--onschedGrey);
  font-weight: 500;
  font-size: .8rem;
}

.editCard .form-group {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.editCard .form-group .form-control {
  margin: 5px;
  min-width: 80px;
  max-width: 110px;
}


.nav-tabs .nav-link {
  background-color: transparent !important;
  border: none;
  color: var(--onschedGrey);
  font-weight: 500;
  font-size: .9rem;
  padding: 5px;
  margin: 10px 30px 0 0;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid var(--onschedBlue);
  color: var(--onschedBlue);
  font-weight: 600;
}

.nav-tabs .nav-link.disabled {
  opacity: .3;
  cursor: not-allowed;
}

/* Custom phone number input field */
.FormatPhone {
  display: inline-block;
  margin: 10px 0;
  width: calc(100% - 30px);
  padding: 10px 10px 5px;
  border: 1px solid var(--onschedShadow);
  background-color: var(--onschedWhite);
  border-radius: 8px;
  outline: none;
  position: absolute;
  left: 15px;
}

/* ProfileCard */

.ProfileCard {
  text-align: left;
}

.ProfileCard button {
  margin-top: 25px;
}

.ProfileCard .renderPhoneMask.noValue {
  color: var(--onschedGrey);
}

.ProfileCard .renderPhoneMask:not(.noValue) {
  color: var(--onschedDark);
}