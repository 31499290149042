.Pages {
  margin-bottom: 15px;
}

.Pages ul {
  justify-content: center;
}

.Pages ul li {
  margin: 0 5px;
}

.Pages ul li .page-link {
  border-color: transparent;
  border-radius: 8px !important;
  font-weight: 600;
  color: var(--onschedGrey);
  cursor: pointer;
}

.Pages ul li.active .page-link {
  background-color: var(--onschedBlue);
  border-color: var(--onschedBlue);
}

.Pages ul li:not(.active):hover .page-link {
  color: var(--onschedBlue);
  background-color: var(--onschedLightBlue);
}