.ToastItem {
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
  font-weight: 500;
  max-height: 45px;
  border-radius: 8px;
  color: var(--onschedDark);
  border: 1px solid var(--onschedGrey);
  overflow: inherit;
  background-color: var(--onschedWhite);
  overflow-wrap: break-word;
  padding-right: 35px;
}

.ToastItem.details {
  border-radius: 8px 8px 0px 8px;
}

.ToastItem .success {
  background-color: rgb(217, 245, 225);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  left: 5px;
  width: 35px;
  margin-right: 15px;
  color: rgb(56, 209, 128);
}

.ToastItem .fail:hover {
  color: var(--onschedWhite) !important;
}

.ToastItem h5 {
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  padding: 12px 0;
}

.ToastItem .toast-header {
  background-color: var(--onschedWhite);
  color: var(--onschedGrey);
  border-radius: 8px;
  padding: 0 0 0 75px;
  border: none;
}

.ToastItem .toast-body {
  color: var(--onschedDark);
  border-radius: 0px 0px 8px 8px;
  text-align: right;
  width: 268px;
  border: 1px solid var(--onschedGrey);
  position: relative;
  border-top: 0;
  top: 0px;
  left: 66px;
  font-weight: 500;
  background-color: var(--onschedWhite);
}

.ToastItem .btn, .ToastItem .btn:focus {
  background-color: rgb(255, 215, 215) !important;
  border-radius: 4px !important;
  color: var(--onschedDanger);
  position: absolute;
  left: 5px;
  box-shadow: none !important;
  padding: 8px;
}

.ToastItem .btn:focus {
  color: var(--onschedDanger) !important;
}

.ToastItem .close {
  position: absolute;
  outline: none;
  box-shadow: none;
  right: 10px;
  color: var(--onschedGrey);
}

.ToastItem:not(.show) {
  display: none;
}