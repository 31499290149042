.FailedMessage {
  margin: 10px 0;
  animation: grow 2s 1 forwards;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: .75rem;
  color: var(--onschedGrey);
}

@keyframes grow {
  0% { height: 0px;}
  10% { height: 18px;}
}

.FailedMessage a {
  color: var(--onschedBlue);
  text-decoration: none;
}