.UploadImage h5 {
  text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: .75rem;
    margin-bottom: 3px;
    margin-left: 7px;
    color: var(--onschedGrey);
}

.UploadImage p {
  color: var(--onschedDanger) !important;
  font-weight: 600 !important;
  opacity: .7;
  margin: 5px;
  text-align: center;
}

.UploadImage {
  margin: 10px 0px;
}